import React, { useState } from 'react'
import { MenuItemLink } from './MenuItemLink'
import { getDomain } from '../../../../../utils/url'
import List from '@material-ui/core/List'
import MenuItem from '@material-ui/core/MenuItem'
import Collapse from '@material-ui/core/Collapse'
import ExpandLess from '@material-ui/icons/ExpandLess'
import ExpandMore from '@material-ui/icons/ExpandMore'
import './MenuItemLink.scss'
import './MobileNavDropdown.scss'

const data = {
  integrations: {
    header: 'Integrations',
    links: {
      navisworks: {
        to: '/integrations/navisworks',
        title: 'Navisworks',
      },
      revit: {
        to: '/integrations/revit',
        title: 'Revit',
      },
      rhino: {
        to: '/integrations/rhino',
        title: 'Rhino',
      },
      sketchup: {
        to: '/integrations/sketchup',
        title: 'SketchUp',
      },
    },
  },
  headsets: {
    header: 'Headset Support',
    links: {
      quest: {
        to: '/oculus-quest',
        title: 'Meta Quest 2',
      },
      riftS: {
        title: 'Oculus Rift S',
        to: '/supported-headsets',
      },
      vive: {
        title: 'HTC Vive',
        to: '/supported-headsets',
      },
      all: { to: '/supported-headsets', title: 'View All' },
    },
  },
  industries: {
    header: 'Industries',
    links: {
      architecture: {
        to: '/industry/architecture',
        title: 'Architecture',
      },
      construction: {
        to: '/industry/construction',
        title: 'Construction',
      },
      engineering: {
        to: '/industry/engineering',
        title: 'Engineering',
      },
      educators: {
        to: '/industry/educators',
        title: 'Educators',
      },
      students: {
        to: '/industry/students',
        title: 'Students',
      },
    },
  },
}

const Dropdown = ({ children, title }) => {
  const [open, setOpen] = useState(false)
  const handleClick = () => setOpen(!open)

  return (
    <List style={{ paddingLeft: 4 }}>
      <MenuItem
        onClick={handleClick}
        style={{
          display: 'flex',
          justifyContent: 'space-between',
        }}
        className="MenuItem"
        disableRipple
        disableTouchRipple
      >
        <div
          style={{
            fontFamily: 'irisvr',
            color: 'black',
            fontSize: 16,
            marginRight: 10,
          }}
        >
          {title}
        </div>
        {open ? <ExpandLess /> : <ExpandMore />}
      </MenuItem>
      <Collapse in={open} timeout="auto" unmountOnExit>
        {children}
      </Collapse>
    </List>
  )
}

const ProductSubnavChildren = ({ links }) => {
  return (
    <List component="div" disablePadding>
      {Object.keys(links).map((key, idx) => {
        return <MenuItemLink key={`data-${key}-${idx}`} {...links[key]} />
      })}
    </List>
  )
}

const ProductChildDropdown = ({ category }) => (
  <Dropdown title={category.header}>
    <ProductSubnavChildren links={category.links} />
  </Dropdown>
)

const IntegrationsDropdown = () => (
  <ProductChildDropdown category={data.integrations} />
)
const HeadsetSupportDropdown = () => (
  <ProductChildDropdown category={data.headsets} />
)
const IndustriesDropdown = () => (
  <ProductChildDropdown category={data.industries} />
)

const ProspectOverviewMenuItem = () => (
  <MenuItemLink
    to="/prospect"
    title="Prospect Overview"
    customStyles={{ marginLeft: -15 }}
  />
)

const staticLinkData = {
  customers: {
    to: '/customers',
    title: 'Customers',
  },
  pricing: {
    to: '/pricing',
    title: 'Pricing',
  },
  account: {
    href: `https://account.${getDomain()}/prospect`,
    title: 'Sign In',
  },
}

const StaticLinks = () => {
  return Object.keys(staticLinkData).map((staticLinkKey, idx) => {
    const linkDetails = staticLinkData[staticLinkKey]
    return (
      <div key={`${staticLinkKey}-${idx}`}>
        <hr className="mobile-nav-menu-divider" />
        <div style={{ paddingTop: 8, paddingBottom: 8 }}>
          <MenuItemLink {...linkDetails} customStyles={{ marginLeft: -13 }} />
        </div>
      </div>
    )
  })
}

export const MobileNavDropdown = () => {
  return (
    <>
      <div style={{ paddingLeft: 3 }}>
        <Dropdown title={'Product'}>
          <div style={{ paddingLeft: 20 }}>
            <List component="div" disablePadding>
              <div style={{ paddingTop: 10 }}>
                <ProspectOverviewMenuItem />
              </div>
              <IntegrationsDropdown />
              <HeadsetSupportDropdown />
              <IndustriesDropdown />
            </List>
          </div>
        </Dropdown>
      </div>
      <StaticLinks />
    </>
  )
}
