import React from 'react'
import PropTypes from 'prop-types'
import { IrisImg, IrisCTALinkButton } from '../../../Shared'
import { getSignupUrl } from '../../../../utils/url'

const signupFormURL = getSignupUrl()

const WhyVR = ({ shouldStack, extraSmall, imageFile }) => (
  <div>
    {shouldStack && (
      <div
        style={{
          paddingBottom: 0,
          paddingTop: 40,
          paddingLeft: 25,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
        className="image-container"
      >
        <IrisImg file={imageFile} alt="Why VR" />
      </div>
    )}
    <section
      className={`why-vr ${shouldStack ? 'ivr-stacked' : 'ivr-row'} ${
        extraSmall ? 'mobile' : ''
      }`}
    >
      {!shouldStack && (
        <div
          style={{
            paddingBottom: 100,
            paddingTop: 40,
          }}
          className="image-container"
        >
          <IrisImg file={imageFile} alt="Why VR" />
        </div>
      )}
      <div className="text-content-container">
        <div className="text-content">
          <div className="preface-container">
            <p className="preface">Why VR</p>
          </div>
          <div className="header-container">
            <p className="header">Win Projects, Save Time, and Catch Errors</p>
          </div>
          <div className="desc-container">
            <p className="desc">
              Communicate your vision with greater clarity and gain the trust of
              clients. Make decisions with speed and confidence by experiencing
              a true to scale space. Run QA/QC to catch costly coordination
              issues.
            </p>
          </div>
        </div>
      </div>
    </section>
  </div>
)

WhyVR.propTypes = {
  shouldStack: PropTypes.bool.isRequired,
  extraSmall: PropTypes.bool.isRequired,
  imageFile: PropTypes.shape().isRequired,
}

export default WhyVR
