import React from 'react'
import { Link } from 'gatsby'
import './GlobalFooter.global.scss'
import { irisOfficeAddress } from '../../../../utils/constants'

class GlobalFooter extends React.PureComponent {
  render() {
    const { mediaType } = this.props

    const Products = () => (
      <div>
        <p className="header">Product</p>
        <p>
          <Link to="/prospect">Prospect Overview</Link>
        </p>
        <p>
          <Link to="/pricing">Pricing</Link>
        </p>
        <p>
          <Link to="/supported-headsets">Supported Headsets</Link>
        </p>
        <p>
          <Link to="/prospect-viewer">Prospect Viewer</Link>
        </p>
      </div>
    )

    const Learn = () => (
      <div>
        <p className="header">Learn</p>
        <p>
          <a
            href="https://help.irisvr.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            Help & FAQ
          </a>
        </p>
        <p>
          <a
            href="https://help.irisvr.com/hc/en-us/community/topics"
            target="_blank"
            rel="noopener noreferrer"
          >
            Forums
          </a>
        </p>
      </div>
    )

    const Integrations = () => (
      <div>
        <p className="header">Integrations</p>
        <p>
          <Link to="/integrations/navisworks">Navisworks</Link>
        </p>
        <p>
          <Link to="/integrations/revit">Revit</Link>
        </p>
        <p>
          <Link to="/integrations/rhino">Rhino</Link>
        </p>
        <p>
          <Link to="/integrations/sketchup">SketchUp</Link>
        </p>
      </div>
    )

    const Industry = () => (
      <div>
        <p className="header">Industry</p>
        <p>
          <Link to="/industry/architecture">Architecture</Link>
        </p>
        <p>
          <Link to="/industry/construction">Construction</Link>
        </p>
        <p>
          <Link to="/industry/engineering">Engineering</Link>
        </p>
        <p>
          <Link to="/industry/educators">Educators</Link>
        </p>
        <p>
          <Link to="/industry/students">Students</Link>
        </p>
      </div>
    )

    const Company = () => (
      <div>
        <p className="header">Company</p>
        <p>
          <Link to="/customers">Customers</Link>
        </p>
        <p>
          <Link to="/about">About</Link>
        </p>
        <p>
          <Link to="/contact">Contact</Link>
        </p>
        <p>
          <a href="https://info.irisvr.com/press">In the News</a>
        </p>
      </div>
    )

    const Terms = ({ width }) => (
      <div className={`copyright ${width}`}>
        <hr />
        <p className="terms">
          <Link to="/terms">Terms</Link> –{' '}
          <a href="https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement">
            Privacy/Cookies
          </a>{' '}
          -{' '}
          <a data-opt-in-preferences href="javascript:;">
            Privacy settings
          </a>{' '}
          -{' '}
          <a
            data-wat-linkname="manage-ccpa-settings-footer-link"
            href="javascript:;"
          >
            Do not sell my personal information
          </a>{' '}
          -{' '}
        </p>
        <p>
          A special thanks to Corgan Architects and Ennead Architects for use of
          their offices for photography
        </p>
        <p>
          Copyright 2024 Prospect by IrisVR, by Autodesk. All rights reserved.
        </p>
      </div>
    )

    return (
      <div id="footer">
        <div
          style={{
            maxWidth: 1180,
            margin: 'auto',
          }}
        >
          {(() => {
            switch (mediaType) {
              case 'tiny':
              case 'extraSmall':
              case 'small':
                return (
                  <div className="col-xs-12">
                    <div className="sitemap col-xs-12">
                      <div className="column col-xs-6">
                        <div className="group">
                          <Products />
                        </div>
                        <div className="group">
                          <Integrations />
                        </div>
                        <div className="group">
                          <Industry />
                        </div>
                      </div>
                      <div className="column col-xs-6">
                        <div className="group">
                          <Learn />
                        </div>
                        <div className="group">
                          <Company />
                        </div>
                      </div>
                    </div>
                    <div className="sitemap col-xs-12">

                    </div>
                    <Terms width="col-xs-12 xs" />
                  </div>
                )
              case 'medium':
                return (
                  <div className="col-md-12 col-sm-12">
                    <div className="sitemap col-md-12 col-sm-12">
                      <div className="column col-md-4 col-sm-4">
                        <div className="group">
                          <Products />
                        </div>
                      </div>
                      <div className="column col-md-4 col-sm-4">
                        <Industry />
                      </div>
                      <div className="column col-md-4 col-sm-4">
                        <Integrations />
                      </div>
                    </div>
                    <div className="sitemap col-md-12 col-sm-12">
                      <div className="column col-md-4 col-sm-4">
                        <Company />
                      </div>
                      <div className="column col-md-4 col-sm-4">
                        <Learn />
                      </div>
                    </div>

                    <div>
                      <Terms width="col-md-12 col-sm-12" />
                    </div>
                  </div>
                )
              case 'large':
                return (
                  <div className="col-md-12 col-sm-12">
                    <div className="sitemap col-md-12 col-sm-12">
                      <div className="column col-md-4 col-sm-4">
                        <div className="group">
                          <Products />
                        </div>
                      </div>
                      <div className="column col-md-4 col-sm-4">
                        <Industry />
                      </div>
                      <div className="column col-md-4 col-sm-4">
                        <Integrations />
                      </div>
                    </div>
                    <div className="sitemap col-md-12 col-sm-12">
                      <div className="column col-md-4 col-sm-4">
                        <Learn />
                      </div>
                      <div className="column col-md-4 col-sm-4">
                        <Company />
                      </div>

                    </div>
                    <Terms width="col-md-12 col-sm-12" />
                  </div>
                )
              default:
                return (
                  <div className="col-lg-12">
                    <div className="sitemap col-lg-9">
                      <div className="column col-lg-3">
                        <div className="group">
                          <Products />
                        </div>
                        <div className="group">
                          <Company />
                        </div>
                      </div>
                      <div className="column col-lg-3">
                        <Industry />
                      </div>
                      <div className="column col-lg-3">
                        <Integrations />
                      </div>
                      <div className="column col-lg-3">
                        <Learn />
                      </div>
                    </div>
                    <Terms width="col-lg-12" />
                  </div>
                )
            }
          })()}
        </div>
      </div>
    )
  }
}

export default GlobalFooter
